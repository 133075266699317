<template>

  <div class="root-container">

    <div :class="communityTokenContainer">
      <div :class="communityTokenContainerLeft">
        <div class="flex-row">
          <div
            class="svg-logo"
            v-html="getRawIcon('RAREIUM_LOGO_LETTER')"
          />
          <h1 class="home-title-text">
            aredex
          </h1>
        </div>

        <div class="home-header-text-container">
          <h2 class="home-header-text">
            Raredex Community Token Now Available!
          </h2>
          <div class="home-header-subtext">
            The much anticipated Raredex community token (RDX) is now available for purchase with USDC. Early participants get 100 RDX to one USDC!
            <br/>
            The contract is deployed at <a href="https://arbiscan.io/token/0x78d2247cab34d540195f334c8ef38d87dd8fd4b8">0x78d2247cab34d540195f334c8ef38d87dd8fd4b8</a> on Arbitrum 
            <div
              class="svg-arbitrum-logo"
              v-html="getRawIcon('ARBITRUM_LOGO')"
            />
          </div>
         
        </div>
        <br/>

        <div class="home-header-subtext">
          What are you getting when you buy the community token?
          <ul>
            <li>Participation in a community that is committed to making access to rare 
                earth metals easier, more efficient, and cheaper.</li>
            <li>Having some rare earth metals associated with the minted tokens <br />
                (note: this is not for investment, it is just to make it fun, and help 
                newcomers with checking the price)</li>
            <li>News from Strategic Metals Invest</li>
            <li>Interacting with like-minded people interested in trading tokens linked
                to the value of rare earths</li>
            <li>Having an early seat at the table normally reserved for governments or 
                the well-connected wealthy</li>
          </ul>
        </div>
        <div class="buy-container">
          <a href="/"><span class="swap-text-community">Buy RDX from the home page</span></a>
          <!-- <div
            class="swap-text-box"
            @click.stop="openSwap()"
          >
            <span class="swap-text-community">Buy Community Token Now</span>
          </div> -->
        </div>
        <br/>
        <div class="home-header-subtext">
          This non-investment pilot engages you with our community and will provide access
          to the rare earth metals ecosystem as we roll out the Raredex platform.
          Participating is a statement of your interest and demonstrating that you feel 
          strongly enough to act on it.
          By helping us build this platform, we help you access the markets first!
        </div>

      </div>
    </div>
  </div>  
  <Transition>
    <CModal
      v-if="showSwapModal"
      color="#5d328d"
      width="medium"
      secondary-color="linear-gradient(338deg,#ff7cf5,#46b6ff)"
      background-color="#333"
      @close="showSwapModal = false"
    >
      <template #header>
        <div> {{ localize("TEXT_SWAP") }} </div>
      </template>
      <template #body>
        <div class="swap-container">
          <Mint
            :sell-token="sellToken"
            :sell-tokens="sellTokens"
            :buy-token="buyToken"
            :buy-tokens="{buyTokens}"
            @hide-modal-event="showSwapModal = false"
            @get-orders="getOrders()"
          />
        </div>
      </template>
      <template #footer>
        <div class="hidden" />
      </template>
    </CModal>
  </Transition>

</template>

<script>
import {Carousel, Navigation, Pagination, Slide, toRaw} from "vue3-carousel";
import {emit, inject, onMounted, reactive, ref, watch} from "vue";
import {useRoute, useRouter} from "vue-router";
import {translationStore} from "@/stores/translation";
import Shape from "./Shape.vue";
import PieChart from "./PieChart.vue";
import Launcher from "./Launcher.vue";
import Mint from "./Mint.vue";
import D3 from "./D3.vue";
import Axios from "axios";
import {iconStore} from "@/stores/icon";

export default {
    "name": "Home",
    "components": {
        Carousel,
        Shape,
        PieChart,
        Slide,
        Pagination,
        Navigation,
        D3,
        Mint,
        Launcher
    },
    setup (props, {emit}) {

        const translation = translationStore(),
            eBus = inject("eBus"),
            serverConfig = inject("serverConfig"),
            userInfo = inject("userInfo"),
            showMetalModal = ref(false),
            showSwapModal = ref(false),
            sellToken = ref("USDC"),
            sellTokens = ref({
                "USDC":{
                    icon: "USDC_ICON",
                    symbol: "USDC",
                    name: "USD Circle",
                    address: "0xaf88d065e77c8cC2239327C5EDb3A432268e5831",
                    balance: 0,
                    price: 1
                }
            }),
            buyToken = ref("RDX"),
            buyTokens = ref({}),
            raredexToken = ref({
                icon: "RAREDEX_RDX_ICON",
                symbol: "RDX",
                name: "Raredex",
                address: "0x78d2247cAb34d540195F334c8ef38D87DD8fD4b8",
                balance: 0,
                price: 1
            }),
            router = useRouter(),
            d3GraphContainer = ref(),
            selectedSymbol = ref(),
            symbols = ref({}),
            currentSlide = ref(),
            icon = iconStore(),
            currentIndex = ref(0),
            email = ref(""),
            chartData = ref({}),
            fiveYearChange = ref(),
            communityTokenContainer = ref(""),
            communityTokenContainerLeft = ref(""),
            communityTokenContainerRight = ref(""),
            homeHeaderText = ref("home-header-text"),
            homeContentText = ref("home-content-text"),
            homeHeaderTextBlack = ref("home-header-text-black"),
            homeContentTextBlack = ref("home-content-text-black"),
            metalContainer = ref(""),
            getAllERC20Tokens = function () {

                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getAllERC20Tokens`,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        for (let i = 0; i < response.data.length; i++) {

                            const tid = response.data[i].symbol;
                            console.log("tid " + tid)


                            if (tid && response.data[i].address.startsWith("0x")) {

                              buyTokens.value[tid] = response.data[i];
                              buyTokens.value[tid].balance = 0;

                              }

                            }

                            // raredexToken
                            buyTokens.value['RDX'] = raredexToken.value;
                            buyTokens.value['RDX'].balance = 0;

                })

            },
            openSwap = function () {

                showSwapModal.value = true;

            },
            openLogin = async function (symbol) {

                    eBus.emit(
                        "app-event",
                        {
                            "action": "showLoginModal",
                            "data": ""
                        }
                    );

            },
            getChartData = async function (symbol) {

                if (tokens.value[selectedSymbol.value] == symbol) {

                    return;

                }

/*
                if (tokens.value[selectedSymbol.value]) {

                    if (tokens.value[selectedSymbol.value].opacity == 1) {

                        tokens.value[selectedSymbol.value].opacity = 0;

                    }

                }
*/
                selectedSymbol.value = 'react';

                let url;

                if (symbol === "RDY") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=dysprosiumoxid';

                } else if (symbol === "RPR") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=praseodymoxid';

                } else if (symbol === "RTB") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=terbiumoxid';

                } else if (symbol === "RGA") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=gallium';

                } else if (symbol === "RGE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=germanium';

                } else if (symbol === "RHF") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=hafnium';

                } else if (symbol === "RIN") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=indium';

                } else if (symbol === "RRE") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=rhenium';

                } else if (symbol === "RND") {

                    url = 'https://charts.tradium.net/wp-json/tradiumcharts/v1/getdata?period=period4&type=neodymoxid';

                } else {

                    return;

                }

                Axios.get(
                    url,
                    {

                        "params": {

                        }

                    }
                ).
                    then((response) => {

                        showMetalModal.value = false;

                        const result = { usd: {}, eur: {} };
                        Object.keys(response.data).forEach((date) => {

                            result['usd'][date.split('.').reverse().join('-')] = parseFloat(response.data[date].usd);
                            result['eur'][date.split('.').reverse().join('-')] = parseFloat(response.data[date].eur);

                        });

                        chartData.value[symbol] = result;


                        selectedSymbol.value = symbol;

                        const dates = Object.keys(chartData.value[symbol].usd);

                        if (dates.length === 0) {

                            return null;

                        }

                        const mostRecentDate = dates.reduce((recentDate, currentDate) => {

                            return recentDate > currentDate ? recentDate : currentDate;

                        });

                        fiveYearChange.value = chartData.value[symbol].usd[mostRecentDate].toFixed(0);

                });

            },
            tokens = ref({
                
                "RDY":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RDY",
                    pts: "Dy",
                    color: "#2FB2FF",
                    name: "Dysprosium",
                    text: "Dysprosium is a chemical element with the symbol Dy and atomic number 66. It is a rare earth element with a metallic silver luster. Dysprosium is seldom encountered as a free element in nature and is usually found in minerals such as xenotime, dysprosium-yttrium fluorite, gadolinite and euxenite. 32Dy makes up about 0.06% of the Earth's crust.",
                    image: "/public/images/Dysprosium.png",
                    opacity: 0,
                    price: 578,
                    ytd: -11.48
                },
                "RND":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RND",
                    pts: "Nd",
                    color: "#2FB2FF",
                    name: "Neodymium",
                    text: "Neodymium is a rare earth element that is found in the earth’s crust. Its metallic element number is 60 and its chemical symbol is Nd. It is categorized as a lanthanide. Neodymium has been used since the early 1950s in different products to create magnets with higher magnetic properties than those made with iron or other metals.",
                    image: "/public/images/Neodymium.png",
                    opacity: 0,
                    price: 123,
                    ytd: -41
                },
                "RPR":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RPR",
                    pts: "Pr",
                    color: "#2FB2FF",
                    name: "Praseodymium",
                    text: "Praseodymium is a chemical element belonging to the rare earth group of metals. It has the atomic number 59 and is represented by the symbol Pr on the periodic table. Discovered in 1885 by Carl Auer von Welsbach, praseodymium has a silvery-white color when in its purest form. Strong praseodymium-iron-boron magnets are commonly used in smartphones, wind turbines, and electric motors due to their superior strength and lightweight properties. ",
                    image: "/public/images/Praseodymium.png",
                    opacity: 0,
                    price: 122,
                    ytd: -38
                },
                "RTB":{
                    icon: "RRAREDEX_RGA_ICON",
                    symbol: "RTB",
                    pts: "Rb",
                    color: "#2FB2FF",
                    name: "Terbium",
                    text: "Terbium is a chemical element with the symbol Tb and atomic number 65. In metal form, terbium is a silvery-white rare earth metal. In its oxide form, in which it is sold commercially, terbium is a black-brown powder. Terbium does not occur naturally and is considered one of the “rarest” of Rare Earths. Some critical use cases are in LCD screens, fiber optics, alloys and nuclear reactors.",
                    image: "/public/images/Terbium.png",
                    opacity: 0,
                    price: 2230.40,
                    ytd: -44.58
                },
                "RGA":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RGA",
                    pts: "Ga",
                    color: "#2FB2FF",
                    name: "Gallium",
                    text: "Gallium is a chemical element with the symbol Ga and atomic number 31. Gallium is a soft, silvery metal, and Elemental gallium is a liquid at temperatures greater than 29.76C (85.57F) (slightly above room temperature). Solid gallium alloys are used in optics, electronics, and nuclear engineering because of their non-toxicity and resistance to neutron radiation and beta decay.",
                    image: "/public/images/Gallium.png",
                    opacity: 0,
                    price: 755,
                    ytd: 17.95
                },
                "RGE":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RGE",
                    pts: "Ge",
                    color: "#2FB2FF",
                    name: "Germanium",
                    text: "Germanium is a chemical element with the symbol Ge and atomic number 32. It is a grayish-white metalloid with a lustrous appearance and is hard and brittle. A relatively rare element, germanium is a metal that has been used in a variety of applications throughout history. In its purest form, germanium is used as a semiconductor in transistors and other electronic devices. Nowadays, just about every industry uses computers.",
                    image: "/public/images/Germanium.png",
                    opacity: 0,
                    price: 2839.40,
                    ytd: 21.11
                },
                "RHF":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RHF",
                    color: "#2FB2FF",
                    pts: "Hf",
                    name: "Hafnium",
                    text: "Hafnium is a chemical element with the symbol Hf and atomic number 72. Found in zirconium minerals, it’s a shiny, silvery, ductile metal that resists corrosion due to the formation of a tough, impenetrable oxide film on its surface. This strategic metal is used in a variety of industries and applications, including Nuclear Reactors, Aerospace, Chemical applications, Microelectronics and much more",
                    image: "/public/images/Hafnium.png",
                    opacity: 0,
                    price: 5298.90,
                    ytd: 16.20
                },
                "RIN":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RIN",
                    pts: "In",
                    color: "#2FB2FF",
                    name: "Indium",
                    text: "Indium is a rare, malleable, and easily workable metallic element with the atomic number 49 and the symbol In. It has a silvery-lustrous gray appearance and is considered one of the least abundant elements in earth’s crust. Indium is an essential and versatile element with various uses across many industries. Indium has put its unique properties to work in consumer electronics, industrial manufacturing, mining exploration, medical technology, metallurgy, and transportation.",
                    image: "/public/images/Indium.png",
                    opacity: 0,
                    price: 561.60,
                    ytd: 26.97
                },
                "RRE":{
                    icon: "RAREDEX_RGA_ICON",
                    symbol: "RRE",
                    pts: "Re",
                    color: "#2FB2FF",
                    name: "Rhenium",
                    text: "Rhenium is an extremely rare and dense chemical element with atomic number 75, which makes it a part of the transitional metals group. It has a silver-grey appearance and melts at an incredibly high temperature of 3,186 degrees Celsius / 5,767 Fahrenheit. Its unique features have caused rhenium to be used in many different areas, from military and aerospace technology to medical research.",
                    image: "/public/images/Rhenium.png",
                    opacity: 0,
                    price: 1942.20,
                    ytd: 20.89
                }

            }),
            slideTo = function (v) {

                currentSlide.value = v;

            },
            getIcon = function (i) {

                if (serverConfig.visualMode.value === "dark") {

                    return icon.get(`${i}_DARK`);

                }
                return icon.get(`${i}_LIGHT`);

            },
            getRawIcon = function (i) {

                return icon.get(`${i}`);

            },
            gotoTab = function (t) {

                router.push({"path": `/${t}/`}).catch((err) => {

                    throw err;

                });

            },
            gotoUrl = function (url) {

                window.open(url, "_blank");

            },
            getNews = function () {

                news.splice(0);
                Axios.get(
                    `${process.env.VUE_APP_SERVER_URI}getNews`,
                    {
                    }
                ).
                    then((response) => {

                        for (const n in response.data) {

                            news.push(response.data[n]);

                        }

                    });

            },
            updateView = function () {

                if (serverConfig.view === "desktop") {

                    communityTokenContainerRight.value = "community-token-container-a-right-desktop"
                    communityTokenContainerLeft.value = "community-token-container-a-left-desktop"
                    communityTokenContainer.value = "community-token-container-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    metalContainer.value = "metal-container-desktop"

                } else if (serverConfig.view === "laptop") {

                    communityTokenContainerRight.value = "community-token-container-a-right-desktop"
                    communityTokenContainerLeft.value = "community-token-container-a-left-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    homeContainer.value = "community-token-container-desktop"
                    metalContainer.value = "metal-container-desktop"

                } else if (serverConfig.view === "tablet") {

                    communityTokenContainerRight.value = "community-token-container-a-right-desktop"
                    communityTokenContainerLeft.value = "community-token-container-a-left-desktop"
                    communityTokenContainer.value = "community-token-container-desktop"
                    d3GraphContainer.value = "graph-container-desktop"
                    metalContainer.value = "metal-container-desktop"

                } else if (serverConfig.view === "mobile") {

                    communityTokenContainerRight.value = "community-token-container-a-right-mobile"
                    communityTokenContainerLeft.value = "community-token-container-a-left-mobile"
                    communityTokenContainer.value = "community-token-container-mobile"
                    d3GraphContainer.value = "graph-container-mobile"
                    metalContainer.value = "metal-container-mobile"

                } else {

                    communityTokenContainerRight.value = "community-token-container-a-right-mobile"
                    communityTokenContainerLeft.value = "community-token-container-a-left-mobile"
                    communityTokenContainer.value = "community-token-container-mobile"
                    d3GraphContainer.value = "graph-container-mobile"
                    metalContainer.value = "metal-container-mobile"

                }

            };

        onMounted(() => {

            getAllERC20Tokens();
            getChartData('RGA');
            updateView();

            let run = function() {

              setInterval(() => {

                currentIndex.value++;

                if (currentIndex.value == 7) {

                  currentIndex.value = 0;

                }

              }, 2500);

            }();

            watch(
                () => email.value,

                (first, second) => {

                    console.log(email.value)

                }

            );

            setTimeout(
                () => {

                },
                100
            );

            const runNews = function () {

                setTimeout(
                    () => {

                    },
                    5000
                );

            };
            runNews();

            watch(

                () => serverConfig.view,
                () => {

                    updateView();

                }

            );

        });

        return {"localize": translation.localize,
            openLogin,
            userInfo,
            email,
            openSwap,
            buyToken,
            buyTokens,
            raredexToken,
            sellToken,
            sellTokens,
            getAllERC20Tokens,
            currentSlide,
            currentIndex,
            fiveYearChange,
            selectedSymbol,
            chartData,
            d3GraphContainer,
            symbols,
            getIcon,
            getRawIcon,
            gotoTab,
            gotoUrl,
            getChartData,
            showMetalModal,
            showSwapModal,
            metalContainer,
            communityTokenContainer,
            communityTokenContainerLeft,
            communityTokenContainerRight,
            homeHeaderText,
            homeContentText,
            homeHeaderTextBlack,
            homeContentTextBlack,
            tokens,
            serverConfig};

    }
};

</script>
<style scoped>
.separado {
  width: 20px;
  height: 20px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
}
.carousel-item {
}
.rare-earth-technology {
  font-size: 5em;
  margin: 100px;
  background: -webkit-repeating-linear-gradient(46deg,#2fb2ff,#11245e,#2fb2ff,#11245e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;
}
.simple-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  padding: 20px;
  color: #5b702c;
  max-width: 800px;
  margin-top: 30px;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
}
.normal-content-text {
  font-size: 1em;
  font-family: Helvetica,"system-ui";
  font-size: 1em;
  margin: 20px;
  padding: 20px;
  color: #5b702c;
  border-radius: 20px;
  background: linear-gradient(1deg,#e2e5db63,transparent);
}
.cursive-content-text {
  max-width: 1000px;
  font-size: 4vh;
  margin-left: auto;
  margin-right: auto;
}
.forty-cent {
  width: 40%;
  margin: 10px;
  align-items: center;
  display: flex;
}
.home-header-container {
  position: relative;
}
video.eq-bg-video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1.1;
  width: 100%;
}
.home-mission-text-container {
  padding-top: 100px;
}
.home-carousel-container {
  min-height: 820px;
}
.metal-container-desktop {
}
.metal-container-mobile {
  display: flex;
  flex-direction: flex-column-reverse;
  width: 100%;
  height: 100%;
}
.metal-image {
}
img.metal-img {
  width: 100%;
  background-size: contain;
}
.metal-text-c {
  max-width: 500px;
}
.metal-text-container {
  padding: 20px;
  justify-content: center;
  align-items: flex-start;
}
.metal-text {
  font-size: 1.1em;
  padding: 20px;
  display: flex;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}
h1.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 5em;
  margin: 0px;
  padding-top: 30px;
}
.home-content-text {
  display: flex;
  align-items: flex-end;
  color: #4e4e4e;
  margin-top: 25px;
  font-size: 2em;
  font-size: 6vh;
  margin: 0px;
}
h1 .home-header-text {
  color: #4d4d4d;
}
h2 .home-header-text {
  color: #4d4d4d;
}
.title-text {
  justify-content: center;
  display: flex;
  font-weight: normal;
  color: #4d4d4d;
}
.home-title-text {
  margin-left: -20px;
  font-weight: normal;
  color: #4d4d4d;
}
.home-header-subtext {
  font-weight: normal;
  color: #4d4d4d;
  font-size: 1.1em;
}
.home-content-text-mobile {
  color: white;
  font-size: 1.2em;
  font-size: 4vh;
  margin: 0px 50px;
}
h1.home-header-text-mobile {
  margin: 50px;
  color: white;
  font-size: 3em;
  font-size: 10vh;
}
.home-content-text-black {
  color: #5f7237;
  font-size: 2em;
  font-size: 6vh;
  margin: 50px;
}
h1.home-header-text-black {
  margin: 50px;
  color: #000;
  font-size: 5em;
  font-size: 15vh;
}
.home-content-text-mobile-black {
  margin: 30px;
  color: #5f7237;
  font-size: 1.2em;
  font-size: 4vh;
}
h1.home-header-text-mobile-black {
  color: #000; 
  font-size: 3em;
  font-size: 10vh;
}
.home-platform-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
img.large-platform-img {
  margin: 10px;
  width: 90%;
}
img.platform-img {
  margin: 10px;
  width: 100%;
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}
.home-curve-container {
}
svg.home-mission-curve {
  position: absolute;
  background: transparent;
  left: 0;
  bottom: 0;
}
svg.home-curve {
  z-index: -2;
  position: absolute;
  background: radial-gradient(ellipse at 50% 70%,#7d5cff,rgba(255,92,244,.47000000000000003));
  left: 0;
}
.raredex-logo {
  position: absolute;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  bottom: 10%;
}
.raredex-logo-img {
  width: 30%;
  max-width: 500px;
  min-width: 300px;
  margin-bottom: 50px;
}
.colorstroke {
  width: 100%;
  height: 100%;
  max-height: 100px;
  max-width: 800px;
  margin-top: 20px;
  margin-bottom: 20px;
  margin-left: auto;
  margin-right: auto;
}
.governance-backdrop {
  background-size: auto;
  background-position: center;
  margin-left: 10px;
  margin-right: 10px;
}
.subtitle {
  max-width: 500px;
  padding: 20px;
  font-size: 3em;
  font-size: 1.0em;
  line-height: 30px;
  text-align: left;
}
a.contactus {
  color: #322e6a;
  text-decoration: underline;
  cursor: pointer;
}
.flex-row {
  display: flex;
  flex-direction: row;
}
.flex-col {
  display: flex;
  flex-direction: column;
}
.good-link {
  color: #3783bb;
}
.home-roadmap-container {
  font-size: 1.1em;
  width: 90%;
  max-width: 1000px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
}
.home-roadmap-box-left-a {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 10px;
  border-bottom: 10px solid #ced4c2;
  border-right: 10px solid #ced4c2;
  background-color: #f8faff;
}
.home-roadmap-box-right-a {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #f8faff;
}
.home-roadmap-box-left-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  background-color: #f8faff;
}
.home-roadmap-box-right-b {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  width: 100%;
  border-left: 10px solid #ced4c2;
  border-bottom: 10px solid #ced4c2;
  background-color: #f8faff;
}
.founders-container {
  max-width: 800px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}
.compdeep-logo {
  cursor: pointer;
  width: 100%;
  height: 100px;
  background-image: url('../assets/compdeep-banner-plain.png');
  margin: 20px;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-size: contain;
  max-width: 300px;
}
.home-footer {
  width: 100%;
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.voxels-container {
  position: relative;
  padding: 0;
  margin-top: 100px;
  width: 100%;
  display: grid;
  place-content: center;
  grid-template-columns: repeat(33,3%);
}
.voxels {
  background: #53b2fe;
  height: 50px;
  width: 50px;
  border-radius: 5px;
  scale: 0;
  opacity: 0;
  animation: box 5s linear infinite;
}
@keyframes box {
  0%, 50%, 90% { scale: 1; opacity: 1 }
  25%, 75%, 100% { scale: 0; opacity: 0 }
}
.root-container {
  width: 100%;
  max-width: 1600px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  overflow: hidden;
}
.community-token-container-desktop {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-left: auto;
  margin-right: auto;
  max-width: 1400px;
}
.community-token-container-mobile {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-evenly;
  align-items: center;
  margin: 10px;
  max-width: 1600px;
}
.community-token-a-left-mobile {
  width: 100%;
}
.community-token-a-right-mobile {
  width: 100%;
}
.community-token-a-left-desktop {
  width: 50%;
}
.community-token-a-right-desktop {
  width: 50%;
  margin-left: 100px;
}
.home-container-a-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  margin: 10px;
  padding: 10px;
  min-width: 300px;
  -webkit-flex: 1;
  -ms-flex: 1;
  flex: 1;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}
.symbolicon {
  display: flex;
  width: 36px;
  height: 36px;
}
.home-container-a-price {
  background-color: none;
  text-align: left;
  -webkit-flex: unset;
  -ms-flex: unset;
  flex: unset;
}
.home-container-metal-name {
  color: #505050;
  font-size: 0.8em;
}
.home-container-a-symbol {
  color: #505050;
  font-weight: bold;
  font-size: 0.6em;
  margin-left: 5px;
  margin-right: 5px;
}
.home-container-a-price {
  color: #505050;
  font-size: 0.8em;
  font-weight: bold;
}
.home-container-a-ytd {
  font-size: 0.8em;
  color: grey; 
  font-weight: bold;
}
.home-container-a-ytd-neg {
  font-size: 0.8em;
  color: #ff7cf5;
  font-weight: bold;
}
.home-container-a-ytd-pos {
  font-size: 0.8em;
  color: #46b6ff;
  font-weight: bold;
}
.community-token-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  max-height: 70vh;
  margin: 5px;
  cursor: pointer;
}
.buy-community {
    color: white;
    font-size: 0.85em;
    font-weight: bold;
    padding: 10px;
    border-radius: 10px;
    width: 200px;
    text-wrap: nowrap;
    background-image: linear-gradient(338deg,#ff7cf5,#46b6ff);
    transition: all 300ms ease-in-out;
}
.buy-community:hover {
    color: white;
    box-shadow: inset 0px 0px 10px 1px #46b6ff, 0px 0px 5px 1px #46b6ff;
    background-image: linear-gradient(338deg,#ff3ff0,#1ea6ff);
}
.flex-row-center {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flex-row-center-space {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 40%;
}
.voxels-text-container {
  position: absolute;
  color: #232b3f;
  font-weight: 700;
  width: 100%;
  text-align: center;
  top: 150px;
}
.buy-container {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
}
.svg-arbitrum-logo {
  width: 96px;
  height: 96px;
  display: flex;
}
.svg-logo {
  display: flex;
}
.home-header-text-container {
}
.home-register-container {
  display: flex;
  align-items: flex-end;
  margin-top: 20px;
  margin-bottom: 20px;
  justify-content: space-between;
}
.bottom-register-container {
  display: flex;
  align-items: center; 
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.home-register-button {
  display: flex;
  margin: 10px;
  width: fit-content;
  align-items: center;
  font-family: Comfortaa,sans-serif;
  text-align: left;
  white-space: nowrap;
  color: white;
  height: 50px;
  font-weight: bold;
  padding-left: 20px;
  padding-right: 20px;
  border-radius: 20px;
  border: none;
  box-shadow: 0px 15px 20px 2px rgb(139 106 224 / 33%);
  cursor: pointer;
  background: linear-gradient(338deg,#ff7cf5,#46b6ff);
}
.home-main-asset {
  min-width: 300px;
  width: 50%;
  height: 400px;
  background-position: top;
  background-size: contain;
  background-repeat: no-repeat;
  background-image: url("../assets/FrameToken.png");
}
.graph-container-desktop {
  height: 50vh;
  width: 50%;
  margin: 10px;
  padding: 10px;
}
.graph-container-mobile {
  height: 80vh;
  width: 100%;
  margin-left: -40px;
}
.pie-container {
  margin-top: -100px;
  height: 500px;
  min-width: 300px;
  width: 50%;
}
.diamond-container {
  height: 500px;
  min-width: 300px;
  width: 50%;
}
.five-year-text {
  min-height: 300px;
  margin: 10px;
}
.five-year-percent-change {
  font-weight: bold;
  color: rgb(47, 178, 255);
}
.five-year-percent-change-text {
  color: #061d2b;
}
.appreciation-container {
  max-width: 500px;
  font-size: 1.1em;
  display: flex;
  flex-direction: column;
  padding: 20px;
}
.slide-fade-enter-active {
    transition: all 1.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(20px);
    opacity: 0;
}
.voxels-text {
  font-size: 9em;
  background: -webkit-repeating-linear-gradient(46deg,#2fb2ff,#11245e,#2fb2ff,#11245e);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
.operations-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #7612b3;
}
.metals-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #40a5e7;
}
.vc-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #ea72e0;
}
.liquidity-slice {
  margin: 10px;
  padding: 1px;
  height: 32px;
  width: 32px;
  background-color: #e1bb0d;
}
.slice-text {
  margin: 10px;
  max-width: 500px;
}
.chart-key-container {
  margin-top: 70px;
}
/*
@keyframes rotateDiamond {
    from {
        transform: rotateX(0deg) rotateY(360deg) rotateZ(360deg);
    }
    to {
        transform: rotateX(360deg) rotateY(360deg) rotateZ(360deg);
    }
}
*/
.telegram-link {
  display: flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
  margin-right: 10px;
  margin:10px;
}
.telegram-link:hover {
  text-decoration: underline;
  cursor: pointer;
}
.telegram-icon {
  margin-right:10px;
  width: 48px;
  height: 48px;
}
.swap-text-box {
  padding: 15px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  border: 2px solid transparent;
  background: #fff;
  box-shadow: 0 15px 20px 2px rgba(139,106,224,.33);
  margin-left: auto;
  margin-right: auto;
}
.swap-text-box:hover {
  border: 2px solid #46b6ff;
  cursor: pointer;
}
.swap-text-community {
  width: 280px;
  font-size: 1.2em;
  font-weight: bold;
  background: -webkit-repeating-linear-gradient(46deg,#46b6ff,#ff7cf5);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}
</style>

<style lang="scss" scoped>

  @for $i from 1 through 1000 {
    .voxels {
      &:nth-child(#{$i}) {
        animation-delay: calc(
          -10s * #{$i} * sin(#{$i})
        );
      }
    }
  }

  @keyframes m {
    0%, 50%, 90% { scale: 1; opacity: 1 }
    25%, 75%, 100% { scale: 0; opacity: 0 }
  }
</style>
